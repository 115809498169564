import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'
import Anakoinwseis from '../components/AnakoinwseisRoll'
import dq  from '../img/aikido_cropped.jpg'
import kenpo from '../img/Kenpo.png'
import angelos from '../img/angelos.png'
import antona from '../img/Antonas.jpg'
import verzi from '../img/Vezirgiannis.jpg'
import '../components/programm.css'
import CookieConsent from "react-cookie-consent";

import Content, { HTMLContent } from "../components/Content";
import image from '../img/aikido_cropped.jpg'



export const IndexPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <div className="content">
    <div className="fixed">
    <div id='image-container'/>
    
      <div id='pipi'
        style={{
          display: 'flex',
          height: '130px',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
          right: '0em',
          bottom:'0.5%',
          position: 'absolute'
        }}
        >
       <p
          style={{
            boxShadow: 'rgb(230 101 7) -0.3rem 0px 0px',
            color: 'white',
            backgroundColor:'#2b2b2b',
            lineHeight: '1.3',
            padding: '1em',
            margin:'20px 0px 0px 20px',
            textAlign:'right',
            fontStyle:'italic'
          }}
        >
       {title}
        </p>
        </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
              
              </h2>
              <PageContent className="content" content={content} />
              
            </div>
            
          </div>
        </div>
      </div>
    </section>
    <section id='blog'>
<div id=''className="section section--gradient">
  <div className="container">
  <div className="section" id=''>
    <div className="columns">
      <div className="column is-10 is-offset-1">
        <div className="content">


                <div className="column is-12" >
                  <h3 style={{color:'#ff4400'}} className="has-text-weight-semibold is-size-2">
                    Blog
                  </h3>

                  <BlogRoll />
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/blog">
                      Διαβάστε Περισσότερα
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>

    <section id='anakoinwseis' className="section section--gradient">
      <div className="container">
      <div className="section" id=''>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="content">


                    <div className="column is-12">
                      <h3 style={{color:'#ff4400'}} className="has-text-weight-semibold is-size-2">
                        Ανακοίνωση
                      </h3>

                      <Anakoinwseis />
                      <div className="column is-12 has-text-centered">
                        <Link className="btn" to="/anakoinwseis">
                          Διαβάστε Περισσότερες
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <CookieConsent  flipButtons
     style={{ background: "#2b2b2b",width:'36%',minWidth:'400px',marginBottom:'26px'}}
     buttonStyle={{ fontWeight: "bold", background: "#ff4400", borderRadius:"0.5em", padding:"5px 10px", fontSize:"1em"}}>
    This website uses cookies to enhance the user experience.
    </CookieConsent>
    </div>
        </div>
      
  );
};


IndexPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const IndexPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const { frontmatter } = data.markdownRemark


  return (
    <Layout>
      <IndexPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
    
      />

   
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
query IndexPageTemplate ($id: String!) {
  markdownRemark(id: { eq: $id }) {
    html
    frontmatter {
      title

    }
  }
}
`;